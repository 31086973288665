import React from 'react';
import classnames from 'classnames';

import { Is, IsComponent, IsProps } from '@chew/common/components/Is';

export type Appearance = 'none' | 'gradient-orange' | 'black' | 'gray' | 'hollow' | 'app-hollow' | 'orange-hollow';

const styles: Record<Appearance, string> = {
  none: 'disabled:cursor-default',
  'gradient-orange':
    'bg-gradient-to-br from-orange-light to-orange-dark text-white shadow-md hover:shadow-orange-light rounded-full disabled:from-purple-light disabled:hover:shadow-md  disabled:to-purple-light disabled:text-purple-medium',
  'orange-hollow':
    'border border-solid border-orange-light bg-white rounded-full hover:bg-orange-light hover:text-white',
  black: 'bg-purple-dark text-white rounded-full',
  hollow: 'bg-white border border-solid border-black text-black rounded-full',
  gray: 'bg-gray-light text-purple-dark rounded-full',
  'app-hollow': 'bg-white border border-solid border-purple-light text-purple-medium rounded-2xl'
};

interface Props {
  appearance?: Appearance;
  fat?: boolean;
}

export const Button = React.forwardRef(
  <T extends IsComponent>(
    {
      // @ts-ignore
      is = 'button',
      appearance = 'none',
      fat = false,
      disabled,
      className,
      children,
      onClick,
      ...rest
    }: Props & IsProps<T>,
    ref: React.Ref<HTMLElement>
  ) => (
    <Is
      {...{ ref, is }}
      className={classnames(`${fat ? 'min-h-[72px]' : 'min-h-[42px]'}`, styles[appearance], className, {
        'group relative flex items-center disabled:cursor-default justify-center transition cursor-pointer focus:outline-none':
          appearance
      })}
      disabled={disabled}
      onClick={disabled ? null : onClick}
      {...rest}
    >
      {children}
    </Is>
  )
);
