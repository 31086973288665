import { Language } from './language';

export const LanguagesBasedFAQs: Record<Language, { question: string; answer: string }[]> = {
  [Language.Norwegian]: [
    {
      question: 'Hvordan kommer man i gang med Chew?',
      answer:
        'Gå inn på «kom i gang» og registrer konto. Når du har opprettet profilen din kan du trykke «oppgrader til et premium abonnement for å se». Betal for abonnementet og vipps har du tilgang til alle leksjonene!'
    },
    {
      question: 'Hva får man tilgang på gjennom et Chew abonnement?',
      answer:
        'Man får et års tilgang på 33 videoleksjoner med Christer Rødseth, der man lærer mange tips og triks på kjøkkenet og ikke minst lager mange herlige retter! Mellom hver leksjon må man igjennom en quiz for å teste kunnskapen og komme videre. Man har også en egen profil der man samler opp poeng gjennom kurset.'
    },
    {
      question: 'Hvilken alderssegment passer Chew for?',
      answer:
        'Barn er forskjellige. Vi har barn helt nede i 6 årsalderen som gjennomfører kurset, men det vil kreve litt mer hjelp og involvering fra foreldre/voksen.'
    },
    {
      question: 'Hva koster Chew og hvor lenge har man tilgang?',
      answer: 'I dag tilbyr vi årsabonnement som koster 1499,- (125 kroner per måned).'
    },
    {
      question: 'Kan flere bruke samme abonnement?',
      answer: 'Per i dag er det kun mulig å registrere et navn per abonnement.'
    },
    {
      question: 'Har dere tatt hensyn til allergener i rettene som skal lages?',
      answer:
        'Kurset har som intensjon å dekke så bredt som mulig når det kommer til forskjellig preferanser i matveien, vi har derfor ikke tatt konkret hensyn til dette. I de aller fleste matrettene vil det likevel være mulig å erstatte ingrediensene med alternativer som f.eks plantebasert melk med kumelk osv.'
    },
    {
      question: 'Hva med vegetarmat?',
      answer:
        'I kurset skal vi gjennom alt fra vegetarmat til forskjellige typer kjøtt. Det er likevel mulig å erstatte kjøtt typer ut fra egne preferanser i de aller fleste rettene, eventuelt bruke plantebaserte alternativer.'
    }
  ]
};
