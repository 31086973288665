import React from 'react';
import classnames from 'classnames';

interface Props {
  isOpen: boolean;
  onClick: () => void;
  appearance: 'app' | 'landing';
}

export const HeaderHamburger: React.FC<Props> = ({ isOpen, appearance, onClick }) => {
  const root = 'h-[2px] my-[2px] transition transform ease-in-out duration-300';

  const background = appearance === 'app' ? 'bg-orange-light' : 'bg-white';

  return (
    <div
      className={classnames(
        appearance === 'app' ? 'bg-white' : 'bg-black',
        'h-10 w-10 rounded-xl shadow-md md:hidden flex flex-col items-end px-3 justify-center z-10'
      )}
      onClick={onClick}
    >
      <div className={classnames(root, background, 'w-4', isOpen && '-rotate-45 translate-y-1.5')} />
      <div className={classnames(root, background, 'w-4', isOpen && 'opacity-0')} />
      <div className={classnames(root, background, 'w-1/2', isOpen && 'rotate-45 -translate-y-1.5 w-[16px]')} />
    </div>
  );
};
