import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { ADDRESS, FACEBOOK_PAGE, INSTAGRAM_PAGE, SUPPORT_EMAIL } from '@/constants/contact';

import logo from '@/assets/logo-light.svg';
import facebookLogo from '@/assets/facebook-logo.svg';
import instagramLogo from '@/assets/instagram-logo.svg';

export const Footer: React.FC = () => {
  return (
    <footer className="flex w-full flex-col items-center bg-footer pt-24 px-4 pb-8 text-white" id="footer">
      <Image src={logo} height="60px" alt="Chew logo" />

      <div className="mt-4 max-w-sm text-center">
        <FormattedMessage id={translations.application.footer.title} />
      </div>

      <div className="flex flex-col md:flex-row items-center w-full max-w-sm justify-between pt-12">
        <div className="flex flex-col items-center">
          <div className="font-extrabold">
            <FormattedMessage id={translations.application.footer.contact} />
          </div>

          <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noopener noreferrer" className="mt-6">
            {SUPPORT_EMAIL}
          </a>

          <div>{ADDRESS}</div>

          <div className="mt-6 flex items-center">
            <a href={INSTAGRAM_PAGE} target="_blank" rel="noopener noreferrer" className="mr-4">
              <Image src={instagramLogo} height="24px" alt="Instagram logo" />
            </a>

            <a href={FACEBOOK_PAGE} target="_blank" rel="noopener noreferrer">
              <Image src={facebookLogo} height="24px" alt="Facebook logo" />
            </a>
          </div>
        </div>

        <div className="flex flex-col mt-10 items-center gap-4 md:gap-0 md:mt-0">
          <Link href="/terms">
            <a>
              <FormattedMessage id={translations.application.footer.terms} />
            </a>
          </Link>

          <Link href="/privacy">
            <a>
              <FormattedMessage id={translations.application.footer.privacy} />
            </a>
          </Link>
        </div>
      </div>

      <div className="mt-20 text-[14px]">
        <FormattedMessage
          id={translations.application.footer.copyright}
          values={{
            year: new Date().getFullYear()
          }}
        />
      </div>
    </footer>
  );
};
