import React, { useMemo } from 'react';

export const useOpen = (defaultIsOpen = false) => {
  const [isOpen, setIsOpen] = React.useState(defaultIsOpen);

  return useMemo(
    () => ({
      isOpen,
      set: setIsOpen,
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
      toggle: () => setIsOpen(!isOpen)
    }),
    [isOpen, setIsOpen]
  );
};
