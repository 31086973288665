import React from 'react';
import Link from 'next/link';
import classnames from 'classnames';

interface Props {
  href: string;
  emoji: string;
  className?: string;
  onClick?(): void;
}

export const HeaderItem = ({ href, emoji, className, onClick, children }: React.PropsWithChildren<Props>) => (
  <Link {...{ href }}>
    <div className={classnames('flex items-center gap-1 cursor-pointer', className)} {...{ onClick }}>
      <div className="h-6 w-6 relative">{emoji}</div>

      <a>{children}</a>
    </div>
  </Link>
);
