import React from 'react';

export type IsComponent = keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>;

export type IsProps<T extends IsComponent> = Omit<React.ComponentProps<T>, 'is'> & {
  is: T;
};

export const Is = React.forwardRef(function <T extends IsComponent>(
  { is: Component, ...rest }: React.PropsWithChildren<IsProps<T>>,
  ref: React.Ref<any>
) {
  // @ts-ignore
  return <Component {...rest} {...{ ref }} />;
});
