import React from 'react';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { ADDRESS, FACEBOOK_PAGE, INSTAGRAM_PAGE, SUPPORT_EMAIL } from '@/constants/contact';

import { useOpen } from '@chew/common/hooks/useOpen';

import { HeaderHamburger } from '@chew/common/components/HeaderHamburger';

import { HeaderItem } from './HeaderItem';
import { HEADER_ITEMS } from '.';

const facebookLogo = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M15 2H18V6H16C14.8954 6 14 6.89543 14 8V10H18L16.9565 14H13.8261V22H9.65217V14H6V10.0396H10V7C10 4.23858 12.2386 2 15 2Z"
      stroke="#736876"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const instagramLogo = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
      stroke="#736876"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M12 16.4445C14.4546 16.4445 16.4444 14.4547 16.4444 12.0001C16.4444 9.54551 14.4546 7.55566 12 7.55566C9.54539 7.55566 7.55554 9.54551 7.55554 12.0001C7.55554 14.4547 9.54539 16.4445 12 16.4445Z"
      stroke="#736876"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M18 6.0217V6" stroke="#736876" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const HeaderMenu: React.FC = () => {
  const header = useOpen(false);

  const itemRoot = 'text-2xl font-bold mt-6';

  return (
    <React.Fragment>
      <HeaderHamburger appearance="landing" onClick={header.toggle} isOpen={header.isOpen} />

      {header.isOpen && (
        <div className="md:hidden fixed inset-0 top-20 bg-white z-10 transition-all flex flex-col items-center overflow-scroll">
          <div className="flex flex-col items-center w-full h-full min-h-max mt-7">
            {HEADER_ITEMS.map(({ text, ...rest }, index) => (
              <HeaderItem key={index} className={itemRoot} {...rest} onClick={() => header.close()}>
                <FormattedMessage id={text} />
              </HeaderItem>
            ))}

            <div className="font-extrabold mt-16 text-lg text-gray">
              <FormattedMessage id={translations.application.footer.contact} />
            </div>

            <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noopener noreferrer" className="mt-2 text-gray">
              {SUPPORT_EMAIL}
            </a>

            <div className="text-gray">{ADDRESS}</div>

            <div className="mt-4 flex items-center">
              <a href={INSTAGRAM_PAGE} target="_blank" rel="noopener noreferrer" className="mr-4">
                {instagramLogo}
              </a>

              <a href={FACEBOOK_PAGE} target="_blank" rel="noopener noreferrer">
                {facebookLogo}
              </a>
            </div>

            <div className="flex flex-col my-11 items-center gap-4 text-gray">
              <Link href="/terms">
                <a>
                  <FormattedMessage id={translations.application.footer.terms} />
                </a>
              </Link>

              <Link href="/privacy">
                <a>
                  <FormattedMessage id={translations.application.footer.privacy} />
                </a>
              </Link>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
