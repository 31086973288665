export const SUPPORT_EMAIL = `support@chew.as`;
export const CONSENT_EMAIL = 'chew@as.no';
export const ADDRESS = `Org: 926 408 682, 1176 Oslo`;
export const PHONE_NUMBER = '425 59 279';

export const FACEBOOK_PAGE = 'https://www.facebook.com/kokkekurs/';
export const INSTAGRAM_PAGE = 'https://www.instagram.com/chew_cooking/';

export const GDPR = 'https://lovdata.no/dokument/NL/lov/2014-06-20-27?q=angrerett';
export const DATA_INSPECTORATE = 'https://www.datatilsynet.no/';
