import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { LOGIN_URL } from '@chew/common/config';

import { Button } from '@chew/common/components/Button';

import { HeaderItem } from './HeaderItem';
import { HeaderMenu } from './HeaderMenu';

import logoOrange from '@/assets/logo-orange.svg';
import { metaPixelAnalytics } from '@/services/analytics/meta-pixel';

export const HEADER_ITEMS = [
  {
    emoji: '🍳',
    href: '/#about',
    text: translations.application.header.about
  },
  {
    emoji: '👩',
    href: '/#parents',
    text: translations.application.header.parents
  },
  {
    emoji: '💭',
    href: '/#faq',
    text: translations.application.header.faq
  },
  {
    emoji: '💰',
    href: '/#pricing',
    text: translations.application.header.pricing
  }
];

export const Header: React.FC = () => {
  const onLoginButtonClick = () => {
    metaPixelAnalytics.trackEvent('Click to app', {});
    window.open(LOGIN_URL, '_self');
  };

  return (
    <header className="relative w-full h-20">
      <div className="fixed inset-x-0 flex items-center justify-center top-0c z-50 h-20 px-4 bg-white shadow-lg">
        <div className="md:max-w-6xl w-full flex justify-between items-center">
          <Link href="/">
            <Image src={logoOrange} height="44px" alt="Chew logo" className="cursor-pointer" />
          </Link>

          <div className="flex items-center md:hidden">
            <Button onClick={() => window.open(LOGIN_URL, '_self')} appearance="black" className="w-24 mr-6 text-body">
              <FormattedMessage id={translations.inputs.buttons.login} />
            </Button>

            <HeaderMenu />
          </div>

          <div className="hidden md:flex items-center justify-between space-x-6 lg:space-x-12 text-header">
            {HEADER_ITEMS.map(({ text, ...rest }, index) => (
              <HeaderItem key={index} {...rest}>
                <FormattedMessage id={text} />
              </HeaderItem>
            ))}
          </div>

          <div className="items-center gap-2 hidden md:flex">
            <Link href="#footer">
              <Button appearance="hollow" className="w-24 text-body">
                <FormattedMessage id={translations.inputs.buttons.contact} />
              </Button>
            </Link>

            <Button onClick={onLoginButtonClick} appearance="black" className="w-24 text-body">
              <FormattedMessage id={translations.inputs.buttons.login} />
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};
